import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import TransactionTable from 'views/admin/default/components/TransactionTable';
import { useAuth } from 'auth-context/auth.context';
import {
  getAllTransactions,
  getAllTransactionsAsAdvisor,
  getInvestmentTypes,
  getInvestmentTypesAsAdvisor
} from '../../../api/transaction-service';
import { COUNT_PER_PAGE } from '../../../config/constant';
import { usePagination, } from '@ajna/pagination';
import { FILTERS, YEAR_1 } from '../../../libraries/transactionsFilterManager';
import { useDebounce } from '../../../libraries/useDebounce';
import { getAllLinkedAccounts, getAllLinkedAccountsAsAdvisor } from '../../../api/institution-service';
import moment from 'moment';
import Card from '../../../components/card/Card';
import AccountBalance from './components/AccountBalance';
import ExpenseRatio from './components/ExpenseRatio';
import { getAccountHoldings, getPriceRangeData } from '../../../api/fasttrack-service';
import MarketDataModal from "../modals/components/MarketDataModal";
import { getPeriodDates } from "../../../libraries/utils";
import { isSupported } from "@loomhq/record-sdk/is-supported";
import { generateLoomJWS, remindInvitation, sendEmailWithVideo } from "../../../api/user-service";
import { setup } from "@loomhq/record-sdk";
import { MdSend, MdVideocam } from "react-icons/md";
import { PopoverTrigger } from "../dataTables/components/ClientTable";
import OfficeInviteModal from "../modals/components/OfficeInviteModal";

const {REACT_APP_SMARTLEAD_BASE_URL = ''} = process.env;

export default function MainDashboard() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();
  const {user, selectedClient} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isInstitutionLoading, setIsInstitutionLoading] = useState(false);
  const [isInvestmentTypesLoading, setIsInvestmentTypesLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(FILTERS);
  const [accounts, setAccounts] = useState([]);
  const [balanceData, setBalanceData] = useState([]);
  const [investmentTypes, setInvestmentTypes] = useState([]);
  const [isSecurityLoading, setIsSecurityLoading] = useState(false);
  const [securities, seSecurities] = useState([]);

  const {isOpen: isHoldingOpen, onOpen: onHoldingOpen, onClose: onHoldingClose} = useDisclosure();
  const {isOpen: isVideoOpen, onOpen: onVideoOpen, onClose: onVideoClose} = useDisclosure();
  const [sharedVideoUrl, setSharedVideoUrl] = useState("");
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [connectType, setConnectType] = useState('CONNECT');
  const {isOpen: isInviteOpen, onOpen: onInviteOpen, onClose: onInviteClose} = useDisclosure();
  const {isOpen: isOfficeOpen, onOpen: onOfficeOpen, onClose: onOfficeClose} = useDisclosure();

  const [selectedSecurity, setSelectedSecurity] = useState(undefined);
  const [isPriceDataLoading, setIsPriceDataLoading] = useState(false);
  const [marketData, setMarketData] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState({
    value: YEAR_1,
    label: '1 year',
  });
  const debouncedFilter = useDebounce(filter, 1000);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    offset,
    pageSize,
  } = usePagination({
    pagesCount: Math.ceil(total / COUNT_PER_PAGE),
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: 1,
      pageSize: COUNT_PER_PAGE,
    },
  });

  const loomButtonRef = useRef(null);

  useEffect(() => {
    fetchInstitutions();
    fetchInvestmentTypes();
    fetchAccountHoldings();
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, offset, debouncedFilter]);


  useEffect(() => {
    fetchPriceRangeData();
  }, [selectedSecurity, selectedDate]);

  useEffect(() => {
    if (selectedClient) {
      setupLoom(selectedClient.userId)
    }
  }, [selectedClient]);

  const fetchPriceRangeData = () => {
    if (!selectedSecurity || !selectedClient) return;
    const [start, end] = getPeriodDates(selectedDate?.value);
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');
    setIsPriceDataLoading(true);
    getPriceRangeData(selectedClient.userId, selectedSecurity.securityId, startDate, endDate)
      .then(data => setMarketData(data))
      .catch(err => {
        setMarketData(undefined);
        toast({
          title: err.response?.data.message ?? err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => setIsPriceDataLoading(false))
  }

  const fetchAccountHoldings = () => {
    setIsSecurityLoading(true);
    getAccountHoldings(selectedClient?.userId)
      .then(({securities}) => {
        seSecurities(securities);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsSecurityLoading(false))
  }

  const setInstitutionList = (response) => {
    let accounts = [];
    let balanceAccounts = [];
    response.forEach((linkedAccount) => {
      for (const item of linkedAccount.items) {
        item.accounts
          .map((account) => {
            const accountName = `${linkedAccount.institutionName} # ${account.accountNumberDisplay}`;
            if (!accounts.some((acc) => acc.value === accountName)) {
              accounts.push({
                value: account.accountId,
                label: accountName,
                isStatementAvailable: item.isStatementAvailable,
                isManual: item.itemId.startsWith('manual_')
              });
            }
            balanceAccounts.push({
              accountId: account.accountId,
              icon: linkedAccount?.branding?.icon || '',
              primaryColor: linkedAccount?.branding?.primaryColor || '',
              institutionName: linkedAccount.institutionName,
              accountName: account.accountName,
              accountNumber: account.accountNumberDisplay,
              type: account.type,
              category: account.category,
              balance: account.balance,
              isStatementAvailable: item.isStatementAvailable,
              isManual: item.itemId.startsWith('manual_')
            })
          });
      }
    });

    let sortedAccounts = accounts.sort((acc1, acc2) => {
      if (acc1.label < acc2.label) return -1;
      if (acc1.label > acc2.label) return 1;
      return 0;
    });
    setAccounts(sortedAccounts);
    let sortedBalanceAccounts = balanceAccounts.sort((acc1, acc2) => {
      if (acc1.name < acc2.name) return -1;
      if (acc1.name > acc2.name) return 1;
      return 0;
    });
    setBalanceData(sortedBalanceAccounts);
  }

  const fetchInstitutions = () => {
    setIsInstitutionLoading(true)
    if (selectedClient) {
      getAllLinkedAccountsAsAdvisor(selectedClient.userId)
        .then((response) => {
          setInstitutionList(response);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsInstitutionLoading(false))
    } else {
      getAllLinkedAccounts()
        .then((response) => {
          setInstitutionList(response);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsInstitutionLoading(false))
    }
  }

  const fetchInvestmentTypes = () => {
    setIsInvestmentTypesLoading(true)
    if (selectedClient) {
      getInvestmentTypesAsAdvisor(selectedClient.userId)
        .then(({investmentTypes}) => {
          const types = investmentTypes.filter(type => type !== null).map(type => ({
            value: type,
            label: `${type}`.toUpperCase(),
          }));
          setInvestmentTypes(types);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsInvestmentTypesLoading(false))
    } else {
      getInvestmentTypes()
        .then(({investmentTypes}) => {
          const types = investmentTypes.filter(type => type !== null).map(type => ({
            value: type,
            label: `${type}`.toUpperCase(),
          }));
          setInvestmentTypes(types);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsInvestmentTypesLoading(false))
    }
  }

  const fetchTransactions = () => {
    setIsLoading(true);
    if (selectedClient) {
      getAllTransactionsAsAdvisor(pageSize, offset, filter, selectedClient.userId)
        .then((response) => {
          setResult(response);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsLoading(false))
    } else {
      getAllTransactions(pageSize, offset, filter)
        .then((response) => {
          setResult(response);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsLoading(false))
    }
  }

  const setResult = (response) => {
    const {transactions, count} = response;
    setTransactions(transactions);
    setTotal(count);
  }

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    })
  }

  const setupLoom = useCallback(async (userId) => {
    try {
      const {supported, error} = await isSupported();
      if (supported) {
        if (loomButtonRef.current) {
          const {jws} = await generateLoomJWS();
          const {configureButton} = await setup({
            jws,
          });
          const sdkButton = configureButton({element: loomButtonRef.current});
          sdkButton.on('insert-click', async (video) => {
            setSharedVideoUrl(video.sharedUrl);
            onVideoOpen();
          });
        }
      } else {
        console.warn(`Loom is not supported: ${error}`);
      }
    } catch (e) {
      console.error(`Loom setup Error: ${e}`);
    }
  }, []);

  const sendVideoEmail = async () => {
    setIsSending(true);

    let errMsg = null;

    if (!subject) {
      errMsg = 'Subject is required.';
    } else if (!message) {
      errMsg = 'Message is required.';
    }

    if (errMsg) {
      toast({
        title: errMsg,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsSending(false);
      return;
    }

    await sendEmailWithVideo(selectedClient.userId, subject, message, sharedVideoUrl)
      .then(() => {
        toast({
          title: 'Email with video has been sent.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        handleVideoEmailClose();
      })
      .catch((err) => {
        toast({
          title: 'Email',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSending(false);
      })
  }

  const handleVideoEmailClose = () => {
    onVideoClose();
    setSubject('');
    setMessage('');
    setIsSending(false);
  }

  const resendInvitationEmail = (userId, category, connectType, reportType) => {
    remindInvitation(userId, category, connectType, reportType)
      .then(() => {
        toast({
          title: 'Email reminder has been sent.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Remind Invitation',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
  }

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      <Flex
        mt="45px"
        mb="20px"
        justifyContent="space-between"
        direction={{base: 'column', md: 'row'}}
        align={{base: 'start', md: 'center'}}>
        <Text color={textColor} fontSize="2xl" fontWeight="700">
          Account Overview
        </Text>
        <Flex gap="8px" wrap='wrap'>
          <Tooltip hasArrow
                   label='Once you record a video, click "Insert Video" to send a message with that recorded video.'>
            <Button
              aria-label="Send an email with video"
              borderRadius="full"
              colorScheme='brand'
              ref={loomButtonRef}
              rightIcon={<MdVideocam/>}
            >
              Record
            </Button>
          </Tooltip>
          <Popover placement="bottom" isOpen={isInviteOpen} onOpen={onInviteOpen} onClose={onInviteClose}>
            <PopoverTrigger>
              <Button
                rightIcon={<MdSend/>}
                fontSize="sm"
                colorScheme="blue"
                fontWeight="500"
                minW='162px'
              >
                Invite Again
              </Button>
            </PopoverTrigger>
            <PopoverContent w="auto">
              <PopoverHeader fontWeight="semibold" pr={8} maxW='240px'>
                Are you sure you want to
                invite {selectedClient?.firstName} {selectedClient?.lastName} to
                smartportfolio™?
              </PopoverHeader>
              <PopoverArrow/>
              <PopoverCloseButton/>
              <PopoverBody maxW='240px'>
                <FormLabel>Invite Type</FormLabel>
                <RadioGroup value={connectType}
                            onChange={(e) => setConnectType(e)}>
                  <HStack>
                    <Radio colorScheme="brandScheme" fontSize='sm'
                           value='CONNECT'>CONNECT</Radio>
                    <Radio colorScheme="brandScheme" fontSize='sm'
                           value='UPLOAD'>UPLOAD</Radio>
                  </HStack>
                  <Radio mt={2} colorScheme="brandScheme" fontSize='sm'
                         value='OFFICE'>OFFICE UPLOAD</Radio>
                  <HStack w='full' justify='end'>
                    <Button
                      mt={2}
                      size='sm'
                      colorScheme='brand'
                      onClick={() => {
                        if (connectType === 'OFFICE') {
                          onOfficeOpen();
                        } else {
                          resendInvitationEmail(selectedClient?.userId, 'Finance', connectType);
                        }
                        onInviteClose();
                      }}
                    >{connectType === 'OFFICE' ? 'Open' : 'Send'}</Button>
                  </HStack>
                </RadioGroup>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          {/*<Select*/}
          {/*  styles={selectCustomStyles(colorMode)}*/}
          {/*  isLoading={isInstitutionLoading}*/}
          {/*  value={filter.ACCOUNT_NAME}*/}
          {/*  isMulti*/}
          {/*  placeholder="Select an account name"*/}
          {/*  onChange={(account) => {*/}
          {/*    handleFilterChange('ACCOUNT_NAME', account)*/}
          {/*  }}*/}
          {/*  options={accounts}*/}
          {/*/>*/}
        </Flex>
      </Flex>
      <Grid templateColumns='repeat(2, 1fr)' gap="20px" mb="20px">
        <GridItem colSpan={{base: 2, xl: 1}}>
          <Card px="0px" mb="20px">
            <AccountBalance
              balanceLoading={isInstitutionLoading}
              balanceData={balanceData}
              setSelectedSecurityId={id => {
                const security = securities.find(sec => sec.securityId === id);
                setSelectedSecurity(security);
              }}
              onMarketDataOpen={onHoldingOpen}
            />
          </Card>
        </GridItem>
        <GridItem colSpan={{base: 2, xl: 1}}>
          <Card px="0px" mb="20px">
            <ExpenseRatio
              isSecurityLoading={isSecurityLoading}
              securityData={securities}
              setSelectedSecurityId={id => {
                const security = securities.find(sec => sec.securityId === id);
                setSelectedSecurity(security);
              }}
              onOpen={onHoldingOpen}
            />
          </Card>
        </GridItem>
      </Grid>
      <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap="20px" mb="20px">
        <TransactionTable
          isLoading={isLoading}
          isInvestmentTypesLoading={isInvestmentTypesLoading}
          investmentTypes={investmentTypes}
          transactions={transactions}
          setCurrentPage={setCurrentPage}
          pagesCount={pagesCount}
          currentPage={currentPage}
          filter={filter}
          setFilter={setFilter}
          isInsLoading={isInstitutionLoading}
          accounts={accounts}
          pages={pages}/>
      </SimpleGrid>
      {
        selectedSecurity &&
        <MarketDataModal isLoading={isPriceDataLoading} isOpen={isHoldingOpen} onClose={onHoldingClose}
                         selectedDate={selectedDate}
                         security={selectedSecurity}
                         handleDateChange={setSelectedDate}
                         marketData={marketData}/>
      }
      <OfficeInviteModal isOpen={isOfficeOpen} onClose={onOfficeClose}
                         selectedUser={selectedClient}
                         url={`${REACT_APP_SMARTLEAD_BASE_URL}/s/${user?.linkSlug}?email=${encodeURIComponent(selectedClient?.email)}&type=OFFICE`}/>
      <Modal isOpen={isVideoOpen} onClose={handleVideoEmailClose} size="xl" isCentered>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Send an email to {selectedClient?.firstName} with video</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Subject</FormLabel>
              <Input colorScheme="brandScheme" value={subject}
                     type="tel"
                     placeholder="Subject"
                     onChange={(e) => setSubject(e.target.value)}/>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Message</FormLabel>
              <Textarea placeholder='Please add message before the meeting...' value={message}
                        rows={5}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}/>
              <Text mt={1} color={textColor}>Note: Your <Link href={sharedVideoUrl} target='_blank' color='blue.500'
                                                              fontWeight='600'>video</Link> will be included in the
                email.</Text>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brandScheme" variant="ghost" onClick={handleVideoEmailClose}>Cancel</Button>
            <Button colorScheme="brandScheme" loadingText='Sending...' isLoading={isSending} isDisabled={isSending}
                    onClick={sendVideoEmail}>Send</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
