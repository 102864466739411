import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Switch,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import {
  addAdvisor,
  deleteIntroVideo,
  generateAPICreds,
  generateLoomJWS,
  getAllAdvisorsByFirmId,
  getImageUploadUrl,
  getUserInfo,
  removeAvatar,
  setSmarttaxStatus,
  updateAdvisor,
  updateBrandColor,
  updateIntroVideoUrl
} from "../../../api/user-service";
import { disConnectProvider } from "../../../api/wealthbox-service";
import { PopoverTrigger } from "../dataTables/components/ClientTable";
import { AddIcon, DeleteIcon, EditIcon, LinkIcon, WarningIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useAuth } from "../../../auth-context/auth.context";
import { ClientType } from "../../../types/user";
import AddAdvisorModal from "../modals/components/AddAdvisorModal";
import moment from "moment";
import { HelpIcon } from "../../../components/icons/Icons";
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { HexColorPicker } from "react-colorful";
import useClickOutside from "../../../libraries/useClickOutside";
import UpdateAdvisorModal from "../modals/components/UpdateAdvisorModal";
import {
  calculateAvailableCreditsAndCharges,
  getAdditionalCreditCost,
  getPlanName,
  getPlanPrice,
  smartfinancePlans,
  smarttaxPlans
} from "../../../libraries/utils";
import { isSupported } from "@loomhq/record-sdk/is-supported";
import { setup } from "@loomhq/record-sdk";
import { oembed } from "@loomhq/loom-embed";
import { MdContentCopy, MdFileUpload, MdVideoCameraFront } from "react-icons/md";
import TrainingVideos from './TrainingVideos';
import { addPaymentMethod, changeSubscription, getSubscriptionInfo } from "../../../api/subscription-service";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";


type ProviderType = 'WEALTHBOX' | 'REDTAIL' | null;

interface TabNameToIndexMap {
  [key: string]: number;
}

const tabNameToIndexMap: TabNameToIndexMap = {
  Basic: 0,
  Subscription: 1,
  Firm: 2,
  Integration: 3,
  Training: 4,
};

const tabIndexToNameMap = Object.fromEntries(
  Object.entries(tabNameToIndexMap).map(([key, value]) => [value, key])
);

const Settings = () => {
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const history = useHistory();
  const toast = useToast();
  const { user: cogUser, setUser: setCogUser } = useAuth();

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = 'secondaryGray.700';

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeTabName = query.get('tab') || 'Basic';
  const [tabIndex, setTabIndex] = useState(tabNameToIndexMap[activeTabName]);

  // Update URL when tab index changes
  useEffect(() => {
    const tabName = tabIndexToNameMap[tabIndex];
    if (tabName) {
      // Construct the new search query string manually
      const newSearch = `?tab=${tabName}`;
      if (location.search !== newSearch) {
        history.push({ search: newSearch });
      }
    }
  }, [tabIndex]);


  useEffect(() => {
    setTabIndex(tabNameToIndexMap[activeTabName]);
  }, [activeTabName]);


  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [user, setUser] = useState<any>(undefined);

  const [isDisConnecting, setIsDisConnecting] = useState<{
    active: boolean,
    provider: ProviderType
  }>({
    active: false,
    provider: null,
  });

  const { isOpen: isAdvisorOpen, onOpen: onAdvisorOpen, onClose: onAdvisorClose } = useDisclosure();

  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState<any>();
  const [avatarUrl, setAvatarUrl] = useState("")
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const imageRef = useRef<any>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [advisors, setAdvisors] = useState([]);
  const maxAvatar = useBreakpointValue({ base: 3, md: 5 });
  const [subscription, setSubscription] = useState<any>(null);
  const [card, setCard] = useState<any>(null);
  const {
    isOpen: isChangeSubscriptionOpen,
    onOpen: onChangeSubscriptionOpen,
    onClose: onChangeSubscriptionClose
  } = useDisclosure();
  const [subscriptionPrev, setSubscriptionPrev] = useState('');
  const [subscriptionTo, setSubscriptionTo] = useState('');
  const [isChangingSubscription, setIsChangingSubscription] = useState(false);
  const [cardHolderName, setCardHolderName] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const {
    isOpen: isChangePaymentMethodOpen,
    onOpen: onChangePaymentMethodOpen,
    onClose: onChangePaymentMethodClose
  } = useDisclosure();

  // Icon
  const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose } = useDisclosure()
  const imageIconRef = useRef<any>(null);
  const [iconUrl, setIconUrl] = useState('');
  const [iconTempUrl, setIconTempUrl] = useState('');
  const [iconFile, setIconFile] = useState<any>(null);
  const [iconCropped, setIconCropped] = useState<Crop>();
  const [iconNaturalWidth, setIconNaturalWidth] = useState(1);

  // Logo
  const imageLogoRef = useRef<any>(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [logoTempUrl, setLogoTempUrl] = useState('');
  const [logoFile, setLogoFile] = useState<any>(null);
  const { isOpen: isLogoOpen, onOpen: onLogoOpen, onClose: onLogoClose } = useDisclosure()

  // Brand Color
  const [brandColor, setBrandColor] = useState('#000000');
  const [brandTempColor, setBrandTempColor] = useState('#000000');
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const close = useCallback(() => setIsBrandOpen(false), []);
  const brandPopOver = useRef<any>();
  useClickOutside(brandPopOver, close);
  const brandBgColor = useColorModeValue("#ffffff", "whiteAlpha.100");
  const [isBrandingSaving, setIsBrandingSaving] = useState(false);

  // Subscription

  // Get the current subscription from cogUser's subscriptions
  const currentFinanceSubscription = user?.subscriptions.find((sub: any) =>
    sub.plan.includes('SMARTFINANCE') && sub.status === 'active'
  );

  const {
    remainingCredits: smartfinanceRemainingCredits,
    charges: smartfinanceCharges
  } = calculateAvailableCreditsAndCharges(currentFinanceSubscription?.plan, subscription?.creditUsed?.smartfinance || 0);

  const currentTaxSubscription = user?.subscriptions.find((sub: any) =>
    sub.plan.includes('SMARTTAX') && sub.status === 'active'
  );

  const {
    remainingCredits: smarttaxRemainingCredits,
    charges: smarttaxCharges
  } = calculateAvailableCreditsAndCharges(currentTaxSubscription?.plan, subscription?.creditUsed?.smarttax || 0);

  // Loom SDK integration
  const { isOpen: isVideoOpen, onOpen: onVideoOpen, onClose: onVideoClose } = useDisclosure();
  const { isOpen: isDeleteVideoOpen, onOpen: onDeleteVideoOpen, onClose: onDeleteVideoClose } = useDisclosure();
  const [videoHTML, setVideoHTML] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoIntroFile, setVideoIntroFile] = useState<any>();
  const loomButtonRef = useRef(null);
  const [isUpdatingVideoUrl, setIsUpdatingVideoUrl] = useState(false);
  const [isDeletingVideoUrl, setIsDeletingVideoUrl] = useState(false);
  const videoRef = useRef<any>(null);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);

  // API Credentials
  const [showApiCredentials, setShowApiCredentials] = useState(false);
  const { onCopy: onClientIDCopy } = useClipboard(user?.apiCreds?.[0]?.clientId);
  const { onCopy: onClientSecretCopy } = useClipboard(user?.apiCreds?.[0]?.clientSecret);
  const [isGeneratingApiCreds, setIsGeneratingApiCreds] = useState(false);

  async function setupLoom() {
    try {
      const { supported, error } = await isSupported();
      if (supported && loomButtonRef.current) {
        const { jws } = await generateLoomJWS();
        const { configureButton } = await setup({
          jws,
        });

        const sdkButton = configureButton({ element: loomButtonRef.current });

        sdkButton.on('insert-click', async (video) => {
          const { html } = await oembed(video.sharedUrl, {});
          setVideoHTML(html);
          setIsUpdatingVideoUrl(true);
          await updateIntroVideoUrl(cogUser.userId, video.sharedUrl);
          setIsUpdatingVideoUrl(false);
        });
      } else {
        console.warn(`Loom is not supported: ${error}`);
      }
    } catch (e) {
      console.error(`Loom setup Error: ${e}`);
    }
  }

  useEffect(() => {
    if (!cogUser) return;
    fetchUserInfo();
    setupLoom();
  }, [cogUser]);

  const fetchUserInfo = () => {
    setIsLoading(true);
    const userInfoPromise = getUserInfo().catch(err => console.error('UserInfo Error:', err));
    const allAdvisorsPromise = getAllAdvisorsByFirmId(cogUser?.firmId).catch(err => console.error('Advisors Error: You are not owner of a firm.'));
    const getSubscriptionInfoPromise = getSubscriptionInfo().catch(err => console.error('Subscription error.', err));

    Promise.all([userInfoPromise, allAdvisorsPromise, getSubscriptionInfoPromise])
      .then(async ([userRes, advisorsRes, subscriptionRes]) => {
        if (userRes) {
          setUser(userRes.user);
          const refreshedAvatarUrl = `${userRes.user?.avatarUrl}?cb=${new Date().getTime()}`;
          setAvatarUrl(refreshedAvatarUrl);
          if (userRes.user?.introVideoUrl?.startsWith('https://www.loom.com/share')) {
            const { html } = await oembed(userRes.user?.introVideoUrl, {});
            setVideoHTML(html);
          } else {
            setVideoUrl(userRes.user?.introVideoUrl);
          }
          setSubscription(subscriptionRes?.subscription);
          setCard(subscriptionRes?.card);
          setCardHolderName(subscriptionRes?.card?.name || '');
          setBrandColor(userRes.user?.firm?.brandColor || '#000000');
          setIconUrl(userRes.user?.firm?.iconUrl ? `${userRes.user?.firm?.iconUrl}?cb=${new Date().getTime()}` : '');
          setLogoUrl(userRes.user?.firm?.logoUrl ? `${userRes.user?.firm?.logoUrl}?cb=${new Date().getTime()}` : '');
        }
        if (advisorsRes) setAdvisors(advisorsRes.advisors);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setIsLoading(false))
  }

  const addNewAdvisor = async ({
                                 email,
                                 firstName,
                                 lastName,
                                 phoneNumber,
                                 role,
                                 note,
                               }: {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber?: string,
    role: 'supervisor' | 'user',
    note?: string,
  }) => {
    setIsAdding(true);
    try {
      await addAdvisor(user.firmId, {
        companyName: user?.fullName,
        receiverEmail: email,
        firstName,
        lastName,
        ...(phoneNumber ? { phoneNumber } : {}),
        role,
        note,
      })
      setIsAdding(false);
      toast({
        title: 'Add Advisor',
        description: `${firstName} has been successfully added as an advisor.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      fetchUserInfo();
    } catch (error: any) {
      setIsAdding(false);
      toast({
        title: 'Add Advisor',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  };

  const disConnect = async (provider: ProviderType) => {
    try {
      setIsDisConnecting({
        active: true,
        provider,
      });
      await disConnectProvider(provider);
      fetchUserInfo();
    } catch (err: any) {
      toast({
        title: err?.response?.data.message || err?.message || 'There was an error occurred. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(err.message);
    } finally {
      setIsDisConnecting({
        active: false,
        provider: null,
      });
    }
  }

  const useCheckProviderConnected = (providerName: 'WEALTHBOX' | 'REDTAIL') => useMemo(() => {
    return user?.accessTokens.some((token: any) => token.provider === providerName);
  }, [providerName, user]);

  const wealthboxConnected = useCheckProviderConnected('WEALTHBOX');
  const redtailConnected = useCheckProviderConnected('REDTAIL');

  const handleSaveImage = async () => {
    setIsUploading(true);
    try {
      if (imageFile && imageUrl) {
        const { uploadUrl } = await getImageUploadUrl(user.userId, cogUser.sub, imageFile.type, 'avatar');

        const result = await axios.put(uploadUrl, imageFile, {
          headers: {
            "Content-Type": imageFile.type,
          },
        });

        if (result.status === 200) {
          setCogUser({
            ...cogUser,
            avatarUrl: imageUrl
          })
        }
      } else {
        await removeAvatar(user.userId, cogUser.sub);
        setAvatarUrl("");
      }

      onClose();
    } catch (error: any) {
      toast({
        title: `Edit image error!`,
        description: error?.response?.data?.message || error?.message,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  }

  const handleSelectImage = async (e: any) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageFile(file);
    setImageUrl(url);
  };


  const handelRemoveAvatar = async () => {
    setImageUrl("");
  };

  const handleSaveIcon = async (type: 'icon' | 'logo') => {
    try {
      if ((type === 'icon' && !iconTempUrl) || !iconCropped || (type === 'logo' && !logoTempUrl)) return;
      setIsUploading(true);
      const d = iconNaturalWidth > 400 ? 400 : iconNaturalWidth; // modal max width is 400 - md
      const scaleX = iconNaturalWidth / d;
      const canvas = document.createElement('canvas');

      // Adjust here according to your needs, I'm using the cropped area's dimensions
      canvas.width = iconCropped?.width || 1;
      canvas.height = iconCropped?.height || 1;

      const ctx = canvas.getContext('2d');

      const img = new window.Image();
      img.src = type === 'icon' ? iconTempUrl : logoTempUrl;

      img.onload = async () => {

        try {
          const croppedImage: Crop = {
            ...iconCropped,
            x: iconCropped.x * scaleX,
            y: iconCropped.y * scaleX,
            // Adjust here according to your needs, I'm using the canvas's dimensions
            width: canvas.width * scaleX,
            height: (type === 'icon' ? canvas.width : canvas.height) * scaleX,
          };

          ctx?.drawImage(
            img,
            croppedImage.x || 0,
            croppedImage.y || 0,
            croppedImage.width || 0,
            croppedImage.height || 0,
            0,
            0,
            canvas.width,
            canvas.height,
          );

          const base64data = canvas.toDataURL(type === 'icon' ? iconFile.type : logoFile.type);
          const resizedImageFile = await fetch(base64data).then(res => res.blob());

          // Same way to obtain the signedUrl
          const { uploadUrl } = await getImageUploadUrl(user.userId, cogUser.sub, type === 'icon' ? iconFile.type : logoFile.type, type === 'icon' ? 'icon' : 'logo');

          await axios.put(uploadUrl, resizedImageFile, {
            headers: {
              "Content-Type": type === 'icon' ? iconFile.type : logoFile.type
            }
          });
          if (type === 'icon') {
            setIconUrl(base64data);
            onImageClose();
          } else {
            setLogoUrl(base64data);
            onLogoClose();
          }
          setIsUploading(false);
        } catch (error: any) {
          toast({
            title: `Edit ${type} error!`,
            description: error?.response?.data?.message || error?.message,
            status: "error",
            isClosable: true,
          });
          setIsUploading(false);
        }
      };
    } catch (error: any) {
      toast({
        title: `Edit ${type} error!`,
        description: error?.response?.data?.message || error?.message,
        status: "error",
        isClosable: true,
      });
      setIsUploading(false);
    }
  };

  function onImageLoad(e: any, type: 'icon' | 'logo') {
    const { offsetWidth: width, offsetHeight: height, naturalWidth, naturalHeight } = e.currentTarget;

    // set original image width, height
    setIconNaturalWidth(naturalWidth);

    let crop: Crop;

    if (type === 'icon') {
      crop = centerCrop(
        makeAspectCrop(
          {
            unit: 'px',
            width,
          },
          1,
          width,
          height
        ),
        width,
        height
      )
    } else {
      crop = {
        unit: 'px',
        width,
        height,
        x: 0,
        y: 0
      }
    }
    setIconCropped(crop)
  }

  const handleSelectIcon = async (e: any, type: 'icon' | 'logo') => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      if (type === 'icon') {
        setIconFile(file);
        setIconTempUrl(url);
        onImageOpen();
      } else if (type === 'logo') {
        setLogoFile(file);
        setLogoTempUrl(url);
        onLogoOpen();
      }
    }
  };

  function onBrandColorChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const hexColorRegex = /^#[0-9A-Fa-f]*$/;
    if (hexColorRegex.test(value)) {
      setBrandTempColor(value);
    }
  }

  async function handleSaveBrandColor() {
    setIsBrandingSaving(true);
    try {
      await updateBrandColor(user.firmId, brandTempColor);
      setBrandColor(brandTempColor);
      setIsBrandOpen(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBrandingSaving(false);
    }
  }

  const updateAdvisorInfo = async ({
                                     companyName,
                                     email,
                                     firstName,
                                     lastName,
                                     phoneNumber,
                                     calendarLink,
                                   }: {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyName: string,
    calendarLink: string,
  }) => {
    try {
      await updateAdvisor(user.userId, {
        fullName: companyName,
        email,
        firstName,
        lastName,
        phoneNumber,
        calendarLink,
      })
      toast({
        title: 'Update Advisor',
        description: `${firstName}'s information has been updated.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchUserInfo();
    } catch (error: any) {
      toast({
        title: 'Update Advisor',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  };

  function onChangeTempBrandHexColor(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const hexColorRegex = /^#[0-9A-Fa-f]*$/;
    if (hexColorRegex.test(value)) {
      setBrandTempColor(value);
    }
  }

  const onSmarttaxFlag = async () => {
    try {
      await setSmarttaxStatus(!user?.isTaxstatusOn ? 'ON' : 'OFF');
      setUser((prev: any) => ({
        ...prev,
        isTaxstatusOn: !user?.isTaxstatusOn
      }));

      toast({
        title: `Smarttax is ${!user?.isTaxstatusOn ? 'ON' : 'OFF'}.`,
        status: !user?.isTaxstatusOn ? 'success' : 'error',
        duration: 3000,
        isClosable: true,
      });

    } catch (e) {
      toast({
        title: 'Smarttax status update failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const handleSelectVideo = async (e: any) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setVideoIntroFile(file);
    setVideoUrl(url);
    onVideoOpen();
  };

  const handleUploadVideo = async () => {
    setIsUploadingVideo(true);
    try {
      if (videoIntroFile && videoUrl) {
        const { uploadUrl } = await getImageUploadUrl(user.userId, cogUser.sub, videoIntroFile.type, 'intro_video');

        const result = await axios.put(uploadUrl, videoIntroFile, {
          headers: {
            "Content-Type": videoIntroFile.type,
          },
        });

        if (result.status === 200) {
          setVideoUrl(videoUrl);
          setVideoHTML("");
        }
      } else {
        // TODO: Remove intro Video;
        setVideoUrl("");
      }

      onVideoClose();
    } catch (error) {
      console.log(error);
      toast({
        title: `Uploading video error!`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsUploadingVideo(false);
    }
  }

  const handleDeleteVideo = async () => {
    setIsDeletingVideoUrl(true);
    try {
      let category: 'loom' | 'file' = 'loom';
      let url = '';
      if (videoHTML || videoUrl) {
        if (videoHTML) {
          category = 'loom';
          url = videoHTML;
        } else {
          category = 'file';
          url = videoUrl;
        }

        await deleteIntroVideo(user.userId, category, url);
        setVideoUrl("");
        setVideoHTML("");
      }
      onDeleteVideoClose();
    } catch (error) {
      console.log(error);
      toast({
        title: `Deleting video error!`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsDeletingVideoUrl(false);
    }
  }

  const handleChangeSubscription = async () => {
    if (!subscriptionTo) return;
    setIsChangingSubscription(true);
    try {
      await changeSubscription(subscriptionPrev, subscriptionTo);
      fetchUserInfo();
      onChangeSubscriptionClose();
    } catch (error) {
      toast({
        title: `Change subscription error!`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsChangingSubscription(false);
    }
  }

  const handleUpdatePaymentMethod = async () => {
    setIsChangingSubscription(true);
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded or Elements were not instantiated
        return;
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return;
      }
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardHolderName,
        },
      });

      if (error) {
        console.log('[error]', error);
        toast({
          title: error?.message || 'Update payment error',
          status: "error",
          isClosable: true,
        });
        return;
      } else {
        console.log('PaymentMethod:', paymentMethod);
        await addPaymentMethod(paymentMethod.id);
        await getSubscriptionInfo()
          .then(({ card }) => {
            setCard(card);
          }).catch(err => console.error(err))
      }
      onChangePaymentMethodClose();
    } catch (error) {
      toast({
        title: `Update payment method error!`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsChangingSubscription(false);
    }
  }

  const handleCopyClick = (type: 'Id' | 'Secret') => {
    if (type === 'Id') {
      onClientIDCopy();
    } else if (type === 'Secret') {
      onClientSecretCopy();
    }
    toast({
      title: `Your Partner ${type} is copied to clipboard.`,
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleGenerateAPICreds = () => {
    setIsGeneratingApiCreds(true);
    generateAPICreds()
      .then(({ data: { clientId } }) => {
        toast({
          title: 'API Credentials',
          description: `API Credentials for Client Id: ${clientId} has been generated.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchUserInfo();
      })
      .catch((err: any) => {
        toast({
          title: `API Credentials`,
          description: err?.response?.data?.message || err.message || 'There was an error occurred. Please  try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsGeneratingApiCreds(false);
      })
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {
        isLoading ? <Flex justify="center" align="center" mt={8}>
          <CircularProgress size={12} isIndeterminate color="green.300"/>
        </Flex> : null
      }
      <Box display={isLoading ? 'none' : 'block'}>
        <Tabs colorScheme='brand'
              index={tabIndex}
              onChange={(index) => setTabIndex(index)}
        >
          <TabList overflowX='auto' overflowY="hidden" whiteSpace="nowrap">
            <Tab>Basic</Tab>
            {
              user?.firmRole === 'admin' ? <Tab>Subscription</Tab> : null
            }
            <Tab>Firm</Tab>
            <Tab>Integration</Tab>
            <Tab>Training</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Grid
                gap={4}
                templateRows={{
                  base: 'none',
                  lg: '1fr',
                }}
                templateColumns={{
                  base: 'none',
                  lg: 'repeat(4, 1fr)',
                }}>
                <GridItem colSpan={1}>
                  <VStack>
                    <Flex align="center" direction='column' mt="80px" position="relative">
                      <Avatar
                        mx="auto"
                        src={avatarUrl}
                        h="140px" // updated to 100px
                        w="140px" // updated to 100px
                        mt="-70px"
                        border="4px solid"
                        borderColor={borderColor}
                      />

                      <Tooltip label="Edit" placement="top">
                        <IconButton
                          pos="absolute"
                          top="-60px"
                          left="calc(50% - 50px)" // Updated to account for smaller size of Avatar
                          isRound
                          colorScheme="brand"
                          icon={<EditIcon/>}
                          size="sm"
                          onClick={() => {
                            setImageUrl(avatarUrl);
                            onOpen();
                          }}
                          aria-label='avatar'/>
                      </Tooltip>
                    </Flex>
                    <Text textAlign='center' fontSize='lg' fontWeight='400' mt='8px'>Advisor
                      Headshot</Text>
                    <Text textAlign='center' fontSize='md' fontWeight='400'
                          color={textColorSecondary}>
                      A high-quality, friendly headshot will make your prospects more relatable and
                      trustworthy.
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem colSpan={{ base: 1, lg: 3 }}>
                  <Grid
                    mt={2}
                    templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                    gap={{ base: 4, md: 8 }}
                  >
                    <FormControl>
                      <FormLabel>First Name</FormLabel>
                      <Input readOnly defaultValue={user?.firstName}/>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Last Name</FormLabel>
                      <Input readOnly defaultValue={user?.lastName}/>
                    </FormControl>
                  </Grid>
                  <Grid mt="16px"
                        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                        gap={{ base: 4, md: 8 }}
                  >
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Input readOnly defaultValue={user?.email}/>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Phone Number</FormLabel>
                      <Input readOnly defaultValue={user?.phoneNumber}/>
                    </FormControl>
                  </Grid>
                  <FormControl mt='16px'>
                    <FormLabel>Company Name</FormLabel>
                    <Input readOnly defaultValue={user?.fullName}/>
                  </FormControl>
                  <FormControl mt='16px'>
                    <FormLabel>Calendar Link <Text display='inline-block' fontSize='sm'
                                                   fontWeight='400'
                                                   color={textColorSecondary}>(This link is included
                      in the success
                      email when a prospect connects an account.)</Text>
                    </FormLabel>
                    <InputGroup>
                      <InputLeftAddon
                        pointerEvents='none'
                        children={<LinkIcon color='brand.300'/>}
                      />
                      <Input readOnly defaultValue={user?.calendarLink}/>
                    </InputGroup>
                  </FormControl>
                  <Button colorScheme="brand" variant="solid" maxW="120px" onClick={onAdvisorOpen}
                          size='sm'
                          mt='16px'>
                    Edit Info
                  </Button>
                </GridItem>
              </Grid>
              <Divider my='20px'/>
              <Text fontWeight='600' color='brand.500' fontSize='lg'>Introduction Video</Text>

              <Flex direction={{ base: 'column', md: 'row' }} align={{ base: 'start', md: 'center' }}
                    gap={4}>
                <Text fontSize='md' fontWeight='400' color={textColorSecondary}>
                  Add/Update a video to the Connect screen for your prospect to "Meet your advisor".
                  By clicking <b>Record</b>, you can start recording, then please click "<b>Insert
                  Video</b>" to save
                  your recorded video. Alternatively, you can upload your intro video file from your
                  local.
                </Text>
                <HStack ml='auto'>
                  {
                    (videoUrl || videoHTML) &&
                    <Button
                      minW='90px'
                      colorScheme='red'
                      size='md'
                      rightIcon={<DeleteIcon/>}
                      onClick={onDeleteVideoOpen}
                      isLoading={isDeletingVideoUrl}
                      isDisabled={isDeletingVideoUrl}
                    >
                      Delete
                    </Button>
                  }
                  <Button colorScheme='brand' size='md' ref={loomButtonRef} minW='90px'
                          rightIcon={<MdVideoCameraFront/>}
                          isLoading={isUpdatingVideoUrl}
                          isDisabled={isUpdatingVideoUrl}
                          loadingText='Uploading...'>Record</Button>
                  <Button colorScheme='brand' size='md' minW='90px'
                          onClick={() => videoRef.current.click()}
                          rightIcon={<MdFileUpload/>}>Upload</Button>
                  <input
                    type="file"
                    accept="video/*"
                    hidden
                    ref={videoRef}
                    onChange={handleSelectVideo}
                  />
                </HStack>
              </Flex>
              {
                (videoUrl || videoHTML) ?
                  <Center>
                    {
                      videoHTML ?
                        <Box mt={4} dangerouslySetInnerHTML={{ __html: videoHTML }}
                             w={{ base: '100%', md: '40%' }}/> :
                        <Box mt={4} w={{ base: '100%', md: '40%' }}>
                          <video height="auto" controls>
                            <source
                              src={videoUrl}/>
                            Your browser does not support the video tag.
                          </video>
                        </Box>
                    }
                  </Center> : null
              }
            </TabPanel>
            {
              user?.firmRole === 'admin' ?
                <TabPanel>
                  <Box>
                    <Text fontSize='lg' color={textColor} fontWeight='500' mt={2}
                          textAlign='center'>BILLING SUMMARY</Text>
                    <Box p={4} borderWidth={1} borderRadius={16} mt={2}>
                      {currentFinanceSubscription && (
                        <VStack align='start' spacing={2}>
                          <Text color='brand.500' fontSize='lg' fontWeight='500'>
                            {getPlanName(currentFinanceSubscription.plan)} - smartportfolio™
                          </Text>
                          <HStack justify='space-between' w='full'>
                            <Text color={textColorSecondary} fontSize='md'>
                              Available data connections:
                            </Text>
                            <Text color={smartfinanceRemainingCredits < 0 ? 'red.500' : textColorSecondary}
                                  fontSize='md' fontWeight='bold'>
                              {smartfinanceRemainingCredits < 0 ? 0 : smartfinanceRemainingCredits}
                            </Text>
                          </HStack>

                          {smartfinanceCharges > 0 && (
                            <HStack justify='space-between' w='full'>
                              <Text color={textColorSecondary} fontSize='md'>
                                Additional smartportfolio™ Charges:
                              </Text>
                              <Text color={textColorSecondary} fontSize='md' fontWeight='bold'>
                                ${getAdditionalCreditCost(currentFinanceSubscription.plan)}/Each
                                * {Math.abs(smartfinanceRemainingCredits)} = ${smartfinanceCharges}
                              </Text>
                            </HStack>
                          )}

                          <HStack justify='space-between' w='full'>
                            <Text color={textColorSecondary} fontSize='md'>
                              Price:
                            </Text>
                            <Text color={textColorSecondary} fontSize='md' fontWeight='bold'>
                              ${getPlanPrice(currentFinanceSubscription.plan).toLocaleString()}/m
                            </Text>
                          </HStack>
                        </VStack>
                      )}
                      {currentTaxSubscription && (
                        <VStack align='start' mt={4} spacing={2}>
                          <Text color='brand.500' fontSize='lg' fontWeight='500'>
                            {getPlanName(currentTaxSubscription.plan)} - smarttax™
                          </Text>
                          <HStack justify='space-between' w='full'>
                            <Text color={textColorSecondary} fontSize='md'>
                              Available data connections:
                            </Text>
                            <Text color={smarttaxRemainingCredits < 0 ? 'red.500' : textColorSecondary} fontSize='md'
                                  fontWeight='bold'>
                              {smarttaxRemainingCredits < 0 ? 0 : smarttaxRemainingCredits}
                            </Text>
                          </HStack>

                          {smarttaxCharges > 0 && (
                            <HStack justify='space-between' w='full'>
                              <Text color={textColorSecondary} fontSize='md'>
                                Additional smarttax™ Charges:
                              </Text>
                              <Text color={textColorSecondary} fontSize='md' fontWeight='bold'>
                                ${getAdditionalCreditCost(currentTaxSubscription.plan)}/Each
                                * {Math.abs(smarttaxRemainingCredits)} = ${smarttaxCharges}
                              </Text>
                            </HStack>
                          )}

                          <HStack justify='space-between' w='full'>
                            <Text color={textColorSecondary} fontSize='md'>
                              Price:
                            </Text>
                            <Text color={textColorSecondary} fontSize='md' fontWeight='bold'>
                              ${getPlanPrice(currentTaxSubscription.plan).toLocaleString()}/m
                            </Text>
                          </HStack>
                        </VStack>
                      )}

                      {
                        (currentFinanceSubscription || currentTaxSubscription) &&
                        <Box>
                          <Divider mt={2}/>

                          <Flex direction={{ base: 'column', md: 'row' }} justify='space-between' w='full' mt={2}>
                            <Text color={textColorSecondary} fontSize='md'>
                              Next billing date
                            </Text>
                            <Text color={textColorSecondary} fontSize='md' fontWeight='bold'>
                              {/*{currentFinanceSubscription.nextBillingDate ? moment(new Date(currentFinanceSubscription.nextBillingDate * 1000)).format("MMM DD, YYYY") : ''}*/}
                              {subscription?.nextBillingDate ? moment(new Date(subscription.nextBillingDate * 1000)).format("MMM DD, YYYY") : ''}
                            </Text>
                          </Flex>
                          <HStack justify='space-between' w='full' mt={1}>
                            <Text color={textColorSecondary} fontSize='md'>
                              Status:
                            </Text>
                            <Badge variant='outline' colorScheme={
                              (currentFinanceSubscription.status === 'active' || currentFinanceSubscription.status === 'trialing') ? 'green' :
                                currentFinanceSubscription.status === 'trial_will_end' ? 'orange' : 'red'
                            }>
                              {currentFinanceSubscription.status}
                            </Badge>
                          </HStack>
                        </Box>
                      }

                      {!currentFinanceSubscription && !currentTaxSubscription && (
                        <Text color={textColorSecondary} fontSize='md' mt={4}>
                          No active subscriptions.
                        </Text>
                      )}
                    </Box>
                  </Box>

                  <Text fontSize='lg' color={textColor} fontWeight='500' mt={8}
                        textAlign='center'>
                    COMPARE PLANS
                  </Text>
                  <Box
                    mt={2}
                    p={4}
                    borderWidth={1}
                    borderRadius={16}>
                    <Text fontWeight='600' color='brand.500'>smartportfolio™</Text>

                    <Flex
                      direction={{ base: 'column', md: 'row' }}
                      justify='space-between'
                      align={{ base: 'start', md: 'center' }}
                      gap={2}>
                      <List spacing={0} mt={2} pl={6} styleType='disc' flex={1}>
                        <ListItem>
                          Add unlimited users from your firm
                        </ListItem>
                        <ListItem>
                          Invite a prospect with an email link or QR code branded with your firm’s logo
                        </ListItem>
                        <ListItem>
                          Add Connect Screen to to new client onboarding form using the smartdata™ website widget
                        </ListItem>
                        <ListItem>
                          Includes new client onboarding form, software integrations, and smartportfolio™ report to
                          show portfolio holdings, tax category, and allocation with total smartfees™ (fund fees,
                          advisor fees, and smarttax™ fee) and smartcast™ for benchmarking portfolio gross and
                          portfolio net against the S&P500 and forecasting.
                        </ListItem>
                        <ListItem>
                          1 smartportfolio™ data connection=1 financial institution (unlimited accounts per financial
                          institution)
                        </ListItem>
                        <ListItem>
                          Download investment statements
                        </ListItem>
                        <ListItem>
                          Real-time market data and investment research
                        </ListItem>
                        <ListItem>
                          Record or upload an intro video to the Connect Screen to new client onboarding form for your
                          prospect to "Meet your advisor"
                        </ListItem>
                        <ListItem>
                          Add a calendar link so your prospective prospect can book a time after they connect their
                          account
                        </ListItem>
                      </List>
                    </Flex>
                    <Table variant="simple" colorScheme="gray" mt={4}>
                      <Thead bg='gray.200'>
                        <Tr>
                          {smartfinancePlans.map(plan => (
                            <Th key={plan.planType} textAlign="center" p={{ base: 1, md: 2 }}>{plan.name}</Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          {smartfinancePlans.map(plan => (
                            <Td key={plan.planType} p={{ base: 1, md: 2 }} textAlign="center">{plan.price}</Td>
                          ))}
                        </Tr>
                        <Tr>
                          {smartfinancePlans.map(plan => (
                            <Td key={plan.planType} p={{ base: 1, md: 2 }} textAlign="center">{plan.credits}</Td>
                          ))}
                        </Tr>
                        <Tr>
                          {smartfinancePlans.map(plan => (
                            <Td key={plan.planType} p={{ base: 1, md: 2 }} textAlign="center">{plan.additionalCost}</Td>
                          ))}
                        </Tr>
                        <Tr>
                          {smartfinancePlans.map(plan => (
                            <Td key={plan.planType} p={{ base: 1, md: 2 }} textAlign="center">
                              <Button
                                size='sm'
                                colorScheme={currentFinanceSubscription?.plan === plan.planType ? 'red' : 'navy'}
                                variant={currentFinanceSubscription?.plan === plan.planType ? 'solid' : 'outline'}
                                isDisabled={currentFinanceSubscription?.plan === plan.planType}
                                onClick={() => {
                                  if (currentFinanceSubscription?.plan !== plan.planType) {
                                    setSubscriptionPrev(currentFinanceSubscription?.plan);
                                    setSubscriptionTo(plan.planType);
                                    onChangeSubscriptionOpen();
                                  }
                                }}
                              >
                                {currentFinanceSubscription?.plan === plan.planType ? 'Current' : 'Subscribe'}
                              </Button>
                            </Td>
                          ))}
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>

                  <Box
                    mt={5}
                    p={4}
                    borderWidth={1}
                    borderRadius={16}>
                    <Text fontWeight='600' color='brand.500'>smarttax™</Text>
                    <Flex
                      direction={{ base: 'column', md: 'row' }}
                      justify='space-between'
                      align={{ base: 'start', md: 'center' }}
                      gap={2}>
                      <List spacing={0} mt={2} pl={6} styleType='disc' flex={1}>
                        <ListItem>
                          Add unlimited users from your firm
                        </ListItem>
                        <ListItem>
                          Fully digital secure workflow
                        </ListItem>
                        <ListItem>
                          Your prospect or client can fill out and digitally sign the IRS form in under 20 seconds
                        </ListItem>
                        <ListItem>
                          Each smarttax™ report is delivered within 10 days, guaranteed
                        </ListItem>
                        <ListItem>
                          Data includes: Three years of Tax Returns and Wage & Income, including Forms 1040, K1s, and
                          1120, directly from the IRS. Total tax liability, Any installment agreements, Effective Tax
                          Rate, Net Capital Gain (Gain or Loss Carryforward)
                        </ListItem>
                        <ListItem>
                          Access your prospect or client’s smarttax™ report for the following 3 years
                        </ListItem>
                        <ListItem>
                          Direct connection to the IRS to pull accurate tax data
                        </ListItem>
                        <ListItem>
                          1 smarttax™ data connection=1 tax order
                        </ListItem>
                        <ListItem>
                          Rely on smarttax™ for speed and certainty when getting tax data
                        </ListItem>
                        <ListItem>
                          Need it expedited? Order a Same-Day smarttax™ for $200 or a Next-Day smarttax™ for $150
                        </ListItem>
                      </List>
                    </Flex>
                    <Table variant="simple" colorScheme="gray" mt={4}>
                      <Thead bg='gray.200'>
                        <Tr>
                          {smarttaxPlans.map(plan => (
                            <Th key={plan.planType} textAlign="center" p={{ base: 1, md: 2 }}>{plan.name}</Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          {smarttaxPlans.map(plan => (
                            <Td key={plan.planType} p={{ base: 1, md: 2 }} textAlign="center">{plan.price}</Td>
                          ))}
                        </Tr>
                        <Tr>
                          {smarttaxPlans.map(plan => (
                            <Td key={plan.planType} p={{ base: 1, md: 2 }} textAlign="center">{plan.credits}</Td>
                          ))}
                        </Tr>
                        <Tr>
                          {smarttaxPlans.map(plan => (
                            <Td key={plan.planType} p={{ base: 1, md: 2 }} textAlign="center">{plan.additionalCost}</Td>
                          ))}
                        </Tr>
                        <Tr>
                          {smarttaxPlans.map(plan => (
                            <Td key={plan.planType} p={{ base: 1, md: 2 }} textAlign="center">
                              <Button
                                size='sm'
                                colorScheme={currentTaxSubscription?.plan === plan.planType ? 'red' : 'navy'}
                                variant={currentTaxSubscription?.plan === plan.planType ? 'solid' : 'outline'}
                                isDisabled={currentTaxSubscription?.plan === plan.planType}
                                onClick={() => {
                                  if (currentTaxSubscription?.plan !== plan.planType) {
                                    setSubscriptionPrev(currentTaxSubscription?.plan);
                                    setSubscriptionTo(plan.planType);
                                    onChangeSubscriptionOpen();
                                  }
                                }}
                              >
                                {currentTaxSubscription?.plan === plan.planType ? 'Current' : 'Subscribe'}
                              </Button>
                            </Td>
                          ))}
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>

                  <Text fontSize='lg' color={textColor} fontWeight='500' mt={8}
                        textAlign='center'>PAYMENT
                    METHOD</Text>
                  {
                    card ? <Flex
                      direction={{ base: 'column', md: 'row' }}
                      justify='space-between'
                      align={{ base: 'start', md: 'center' }}
                      mt={2}
                      p={4}
                      gap={2}
                      borderWidth={1}
                      borderRadius={16}>
                      <Box flex={1}>
                        <HStack align='center'>
                          <Text color={textColor} fontSize='md'>
                            Card Number:
                          </Text>
                          <Text color={textColor} fontSize='md' fontWeight='bold'>
                            {`xxxx-xxxx-xxxx-${card?.last4}`}
                          </Text>
                        </HStack>
                        <HStack align='center' mt={1}>
                          <Text color={textColor} fontSize='md'>
                            Card Name:
                          </Text>
                          <Text color={textColor} fontSize='md' fontWeight='bold'>
                            {card?.name}
                          </Text>
                        </HStack>
                        <HStack align='center' mt={1}>
                          <Text color={textColor} fontSize='md'>
                            Expiry Date:
                          </Text>
                          <Text color={textColor} fontSize='md' fontWeight='bold'>
                            {card?.expMonth} / {card?.expYear}
                          </Text>
                        </HStack>
                        <HStack align='center' mt={1}>
                          <Text color={textColor} fontSize='md'>
                            Card Type:
                          </Text>
                          <Text color={textColor} fontSize='md' fontWeight='bold'>
                            {card?.brand}
                          </Text>
                        </HStack>
                      </Box>
                      <Button size='sm' colorScheme='brand' variant='outline' minW='120px'
                              mx='auto'
                              onClick={onChangePaymentMethodOpen}>Modify</Button>
                    </Flex> : <Text color={textColorSecondary}>No payment method added!</Text>
                  }
                </TabPanel> : null
            }
            <TabPanel>
              <Text fontWeight='600' color='brand.500' fontSize='lg'>Branding</Text>
              <Flex my='12px' direction={{ base: 'column', md: 'row' }} justify='space-around' gap={4}>
                <VStack align='start'>
                  <HStack>
                    <Text fontWeight='400'>Logo Icon</Text>
                    <Tooltip
                      label="A smaller version of your logo (such as a favicon). It will show up on the connect link screen. All QR Codes of advisors associated with your firm will be automatically re-generated when you upload a new icon."
                      hasArrow placement="top">
                      <HelpIcon/>
                    </Tooltip>
                  </HStack>
                  <VStack>
                    <Image
                      src={iconUrl ? iconUrl : require("../../../assets/img/unknown-logo-icon.png")}
                      h='60px'
                      alt='default icon'/>
                    <VStack display={user?.firmRole === 'admin' ? 'flex' : 'none'}>
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        ref={imageIconRef}
                        onChange={e => handleSelectIcon(e, 'icon')}
                      />
                      <Text fontSize='sm' textColor={textColorSecondary}>
                        Upload a 256 x 256 or larger square image.
                      </Text>
                      <Button colorScheme='brand' size='sm'
                              onClick={() => imageIconRef.current.click()}>Upload</Button>
                    </VStack>
                  </VStack>
                </VStack>
                <VStack align='start'>
                  <HStack>
                    <Text fontWeight='400'>Firm Logo</Text>
                    <Tooltip label="The full-size version of your logo" hasArrow placement="top">
                      <HelpIcon/>
                    </Tooltip>
                  </HStack>
                  <VStack>
                    <Image
                      src={logoUrl ? logoUrl : require("../../../assets/img/unknown-company-logo.png")}
                      h='60px'
                      alt='default logo'/>
                    <VStack display={user?.firmRole === 'admin' ? 'flex' : 'none'}>
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        ref={imageLogoRef}
                        onChange={e => handleSelectIcon(e, 'logo')}
                      />
                      <Text fontSize='sm' textColor={textColorSecondary}>
                        Max file size: 25mb. jpg, jpeg, png, webp
                      </Text>
                      <Button colorScheme='brand' size='sm'
                              onClick={() => imageLogoRef.current.click()}>Upload</Button>
                    </VStack>
                  </VStack>
                </VStack>
                <VStack align='start'>
                  <HStack>
                    <Text fontWeight='400'>Brand color</Text>
                    <Tooltip
                      label="Brand color is used as active color of your connect link screen and QR code."
                      hasArrow
                      placement="top">
                      <HelpIcon/>
                    </Tooltip>
                  </HStack>
                  <VStack position='relative'>
                    <InputGroup h='60px'>
                      <InputLeftElement bg={isBrandOpen ? brandTempColor : brandColor}
                                        borderRadius='8px'
                                        onClick={() => {
                                          if (user?.firmRole === 'admin') {
                                            setIsBrandOpen(true);
                                            setBrandTempColor(brandColor);
                                          }
                                        }}
                                        cursor={user?.firmRole === 'admin' ? 'pointer' : 'not-allowed'}/>
                      <Input pl={12} defaultValue={brandColor}
                             value={isBrandOpen ? brandTempColor : brandColor}
                             onChange={onBrandColorChange}/>
                    </InputGroup>
                    {
                      isBrandOpen && (
                        <Box
                          top={12}
                          ref={brandPopOver}
                          position='absolute'
                          zIndex={1}
                          bg={brandBgColor}
                          borderRadius='8px'
                          borderWidth='1px'
                          borderColor='gray.400'
                          p={4}
                        >
                          <HexColorPicker color={brandTempColor} onChange={setBrandTempColor}/>
                          <Input value={brandTempColor} onChange={onChangeTempBrandHexColor}
                                 size='sm' mt='12px'
                                 maxLength={7}/>
                          <HStack mt={2} justify='end'>
                            <Button colorScheme='red' size='sm' variant='outline'
                                    onClick={() => {
                                      setIsBrandOpen(false)
                                      setBrandTempColor(brandColor)
                                    }}>Cancel</Button>
                            <Button colorScheme='brand' size='sm' isLoading={isBrandingSaving}
                                    isDisabled={isBrandingSaving}
                                    onClick={handleSaveBrandColor}>Save</Button>
                          </HStack>
                        </Box>
                      )
                    }
                  </VStack>
                </VStack>
              </Flex>
              <Divider my='20px'/>
              <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <FormControl>
                    <FormLabel>Company Name</FormLabel>
                    <Input readOnly defaultValue={user?.fullName}/>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <FormControl>
                    <FormLabel>Role</FormLabel>
                    <Input readOnly
                           defaultValue={user?.firmRole === 'admin' ? 'OWNER' : user?.firmRole === 'user' ? 'ADVISOR' : user?.firmRole}/>
                  </FormControl>
                </GridItem>
              </Grid>
              <Divider my='20px'/>
              <Box display={user?.firmRole === 'admin' ? 'block' : 'none'}>
                <Text color='brand.500' fontWeight='500'>Firm Users</Text>
                <Flex mt='8px' justify='space-between' align='center'>
                  {
                    advisors?.length > 0 ?
                      <HStack>
                        <AvatarGroup size='md' max={maxAvatar}>
                          {advisors.map((client: ClientType) => (
                            <Avatar key={client.userId}
                                    name={`${client.firstName} ${client.lastName}`}
                                    src={client.avatarUrl}/>
                          ))}
                        </AvatarGroup>
                        <Button variant='link' color='navy.600' fontSize='sm'
                                onClick={() => history.push('/admin/users')}>View
                          All</Button>
                      </HStack> : <Text color={textColorSecondary}>No advisors invited!</Text>
                  }

                  <Button
                    leftIcon={<AddIcon/>}
                    fontSize="sm"
                    size='sm'
                    variant="brand"
                    fontWeight="500"
                    onClick={onAddOpen}
                  >
                    Add
                  </Button>
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'
                      align={{ base: 'end', md: 'center' }} mt={4} gap={2}>
                  <HStack justify='start' align={{ base: 'start', md: 'center' }}>
                    <Image src={require('../../../assets/img/financials/wealthbox-icon.png')}
                           h="40px"
                           borderRadius="full"/>
                    <Box>
                      <Text fontSize='lg' fontWeight='600'>Wealthbox</Text>
                      <Text fontSize='md' fontWeight='400' color={textColorSecondary}>
                        Retrieve prospects from Wealthbox, send invites effortlessly, and then sync
                        back the Assets
                        Under Management (AUM) details to Wealthbox for a seamless experience.
                      </Text>
                    </Box>
                  </HStack>
                  {
                    wealthboxConnected ? <Popover placement="auto-start">
                        {({ onClose }) => (
                          <>
                            <PopoverTrigger>
                              <Button
                                isLoading={isDisConnecting.active && isDisConnecting.provider === 'WEALTHBOX'}
                                isDisabled={isDisConnecting.active && isDisConnecting.provider === 'WEALTHBOX'}
                                size='sm' colorScheme='red' minW='100px'>Disconnect</Button>
                            </PopoverTrigger>
                            <PopoverContent w="auto" maxW="280px">
                              <PopoverHeader fontWeight="semibold">Disconnect
                                Wealthbox</PopoverHeader>
                              <PopoverArrow/>
                              <PopoverCloseButton/>
                              <PopoverBody>
                                <Box display="flex" marginBottom="8px">
                                  <WarningIcon color="orange" marginRight="4px"
                                               marginTop="4px"/>
                                  <Box>Your Wealthbox data will no longer be automatically
                                    synced. Will you
                                    proceed?</Box>
                                </Box>
                                <Box display="flex" justifyContent="flex-end">
                                  <Button size="xs" margin="4px" width="50px"
                                          onClick={onClose}>No</Button>
                                  <Button size="xs" margin="4px" width="50px"
                                          colorScheme="red"
                                          onClick={() => {
                                            disConnect('WEALTHBOX');
                                            onClose();
                                          }}>Yes</Button>
                                </Box>
                              </PopoverBody>
                            </PopoverContent>
                          </>
                        )}
                      </Popover> :
                      <Badge colorScheme={wealthboxConnected ? 'green' : 'gray'}
                             variant='outline'>{wealthboxConnected ? 'Connected' : 'Not Connected'}
                      </Badge>
                  }
                </Flex>

                <Divider my={4}/>

                <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'
                      align={{ base: 'end', md: 'center' }} gap={2}>
                  <HStack justify='start' align={{ base: 'start', md: 'center' }}>
                    <Image src={require('../../../assets/img/financials/redtail-reversed-icon.png')}
                           h="40px"
                           borderRadius="full"/>
                    <Box>
                      <Text fontSize='lg' fontWeight='600'>Redtail</Text>
                      <Text fontSize='md' fontWeight='400' color={textColorSecondary}>Retrieve
                        contact data from
                        Redtail to send invites to prospects and send back data.</Text>
                    </Box>
                  </HStack>
                  {
                    redtailConnected ? <Popover placement="auto-start">
                        {({ onClose }) => (
                          <>
                            <PopoverTrigger>
                              <Button
                                isLoading={isDisConnecting.active && isDisConnecting.provider === 'REDTAIL'}
                                isDisabled={isDisConnecting.active && isDisConnecting.provider === 'REDTAIL'}
                                size='sm' colorScheme='red'>Disconnect</Button>
                            </PopoverTrigger>
                            <PopoverContent w="auto" maxW="280px">
                              <PopoverHeader fontWeight="semibold">Disconnect
                                Redtail</PopoverHeader>
                              <PopoverArrow/>
                              <PopoverCloseButton/>
                              <PopoverBody>
                                <Box display="flex" marginBottom="8px">
                                  <WarningIcon color="orange" marginRight="4px"
                                               marginTop="4px"/>
                                  <Box>Your Redtail data will no longer be automatically
                                    synced. Will you
                                    proceed?</Box>
                                </Box>
                                <Box display="flex" justifyContent="flex-end">
                                  <Button size="xs" margin="4px" width="50px"
                                          onClick={onClose}>No</Button>
                                  <Button size="xs" margin="4px" width="50px"
                                          colorScheme="red"
                                          onClick={() => {
                                            disConnect('REDTAIL')
                                            onClose();
                                          }}>Yes</Button>
                                </Box>
                              </PopoverBody>
                            </PopoverContent>
                          </>
                        )}
                      </Popover> :
                      <Badge colorScheme={redtailConnected ? 'green' : 'gray'}
                             variant='outline'>{redtailConnected ? 'Connected' : 'Not Connected'}</Badge>
                  }
                </Flex>

                <Divider my={4}/>

                <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'
                      align={{ base: 'end', md: 'center' }} gap={2}>
                  <HStack justify='start' align={{ base: 'start', md: 'center' }}>
                    <Image src={require('../../../assets/img/financials/salesforce-icon.png')}
                           h="40px"
                           borderRadius="full"/>
                    <Box>
                      <Text fontSize='lg' fontWeight='600'>Salesforce</Text>
                      <Text fontSize='md' fontWeight='400' color={textColorSecondary}>Retrieve
                        contact data from
                        Salesforce to send invites to prospects and send back data.</Text>
                    </Box>
                  </HStack>
                  <Text color={textColorSecondary} fontWeight='500'>COMING SOON</Text>
                </Flex>

                <Divider my={4}/>

                <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'
                      align={{ base: 'end', md: 'center' }} gap={2}>
                  <HStack justify='start' align={{ base: 'start', md: 'center' }}>
                    <Image src={require('../../../assets/img/financials/nitrogen-icon.png')}
                           h="40px"
                           borderRadius="full"/>
                    <Box>
                      <Text fontSize='lg' fontWeight='600'>Nitrogen</Text>
                      <Text fontSize='md' fontWeight='400' color={textColorSecondary}>
                        Send to Nitrogen, an all-in-one client engagement software platform.
                      </Text>
                    </Box>
                  </HStack>
                  <Text color={textColorSecondary} fontWeight='500'>COMING SOON</Text>
                </Flex>

                <Divider my={4}/>

                <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'
                      align={{ base: 'end', md: 'center' }} gap={2}>
                  <HStack justify='start' align={{ base: 'start', md: 'center' }}>
                    <Image src={require('../../../assets/img/financials/holistiplan-icon.png')}
                           h="40px"
                           borderRadius="full"/>
                    <Box>
                      <Text fontSize='lg' fontWeight='600'>Holistiplan</Text>
                      <Text fontSize='md' fontWeight='400' color={textColorSecondary}>
                        Send SmartTax data directly to Holistiplan, a top rated tax & estate planning software.
                      </Text>
                    </Box>
                  </HStack>
                  <Text color={textColorSecondary} fontWeight='500'>COMING SOON</Text>
                </Flex>

                <Divider my={4}/>

                <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'
                      align={{ base: 'end', md: 'center' }} gap={2}>
                  <HStack justify='start' align={{ base: 'start', md: 'center' }}>
                    <Image src={require('../../../assets/img/icon.png')} h="40px"
                           borderRadius="full"/>
                    <Box>
                      <Text fontSize='lg' fontWeight='600'>Smarttax</Text>
                      <Text fontSize='md' fontWeight='400' color={textColorSecondary}>
                        Tax Connect email inviting your prospect to connect their IRS account will
                        be sent after they
                        successfully connected their finance account. A Smarttax invite can also
                        be sent by itself.
                      </Text>
                    </Box>
                  </HStack>
                  <Switch colorScheme='brand' size='lg' onChange={onSmarttaxFlag}
                          isChecked={user?.isTaxstatusOn}/>
                </Flex>

                <Divider my={4}/>
                <Text fontSize='lg' fontWeight='600'>API Credentials</Text>
                <Text fontSize='md' fontWeight='400' color={textColorSecondary}>
                  API credentials, including <b>SMARTDATA-PARTNER-ID</b> and <b>SMARTDATA-PARTNER-SECRET</b> must be
                  included in the header of your API
                  requests. Refer to the documentation <Link
                  color='blue'
                  href='https://docs.smartdatawealth.com'
                  target='_blank'>
                  here
                </Link> for more information.
                </Text>

                {showApiCredentials && (
                  <>
                    <FormControl mt={2}>
                      <FormLabel>SMARTDATA-PARTNER-ID</FormLabel>
                      <HStack>
                        <Input readOnly defaultValue={user?.apiCreds?.[0]?.clientId}/>
                        <Button
                          mt={2}
                          colorScheme='green'
                          leftIcon={<MdContentCopy/>}
                          size='sm'
                          onClick={() => handleCopyClick('Id')}
                          isDisabled={user?.apiCreds?.length === 0}
                        >
                          Copy
                        </Button>
                      </HStack>
                    </FormControl>

                    <FormControl mt={2}>
                      <FormLabel>SMARTDATA-PARTNER-SECRET</FormLabel>
                      <HStack>
                        <Input readOnly defaultValue={user?.apiCreds?.[0]?.clientSecret}/>
                        <Button
                          mt={2}
                          colorScheme='green'
                          leftIcon={<MdContentCopy/>}
                          size='sm'
                          onClick={() => handleCopyClick('Secret')}
                          isDisabled={user?.apiCreds?.length === 0}
                        >
                          Copy
                        </Button>
                      </HStack>
                    </FormControl>
                  </>
                )}
                {
                  user?.apiCreds?.length > 0 ?
                    <Button size='sm' colorScheme='brand' mt={2}
                            onClick={() => setShowApiCredentials(!showApiCredentials)}>
                      {showApiCredentials ? 'Hide API Credentials' : 'Show API Credentials'}
                    </Button> :
                    <Button
                      leftIcon={<AddIcon/>}
                      fontSize="sm"
                      variant="brand"
                      mt={2}
                      onClick={handleGenerateAPICreds}
                      isLoading={isGeneratingApiCreds}
                      isDisabled={isGeneratingApiCreds}
                    >
                      Generate Credentials
                    </Button>
                }
              </Box>
            </TabPanel>
            <TabPanel>
              <TrainingVideos/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Edit Avatar</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Box textAlign="center">
              <Tooltip label="Select image" placement="top">
                <Avatar
                  mx="auto"
                  src={imageUrl}
                  h="200px"
                  w="200px"
                  border="4px solid"
                  borderColor={borderColor}
                  _hover={{
                    opacity: 0.7,
                  }}
                  onClick={() => imageRef.current.click()}
                />
              </Tooltip>
            </Box>
            <input
              type="file"
              accept="image/*"
              hidden
              ref={imageRef}
              onChange={handleSelectImage}
            />
            <Tooltip label="Remove" placement="top">
              <IconButton
                float="right"
                variant="ghost"
                colorScheme="brand"
                icon={<DeleteIcon/>}
                onClick={handelRemoveAvatar}
                aria-label='remove avatar'/>
            </Tooltip>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="brand"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleSaveImage}
              isLoading={isUploading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddAdvisorModal isLoading={isAdding} isOpen={isAddOpen} onClose={onAddClose} onSave={addNewAdvisor}/>
      <Modal isOpen={isImageOpen} onClose={() => {
        onImageClose()
        imageIconRef.current.value = '';
      }}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Crop your icon</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <ReactCrop
              aspect={1}
              circularCrop
              crop={iconCropped}
              keepSelection
              onChange={c => setIconCropped(c)}
            >
              <img src={iconTempUrl} alt='logo' onLoad={e => onImageLoad(e, 'icon')}/>
            </ReactCrop>
            <Text color={textColorSecondary}>Note: All QR Codes of advisors associated with your firm will
              be
              automatically re-generated when you upload a new icon.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='brand' mr={3} variant='outline' onClick={() => {
              onImageClose()
              imageIconRef.current.value = '';
            }}>
              Close
            </Button>
            <Button variant='solid' colorScheme='brand' onClick={() => handleSaveIcon('icon')}
                    isLoading={isUploading} isDisabled={isUploading}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isLogoOpen} onClose={() => {
        onLogoClose()
        imageLogoRef.current.value = '';
      }}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Crop your logo</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <ReactCrop
              crop={iconCropped}
              onChange={c => setIconCropped(c)}
            >
              <img src={logoTempUrl} alt='logo' onLoad={e => onImageLoad(e, 'logo')}/>
            </ReactCrop>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='brand' mr={3} variant='outline' onClick={() => {
              onLogoClose()
              imageLogoRef.current.value = '';
            }}>
              Close
            </Button>
            <Button variant='solid' colorScheme='brand' onClick={() => handleSaveIcon('logo')}
                    isLoading={isUploading} isDisabled={isUploading}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <UpdateAdvisorModal isOpen={isAdvisorOpen} onClose={onAdvisorClose} user={user}
                          onSave={updateAdvisorInfo}/>
      <Modal isOpen={isVideoOpen} onClose={() => {
        setVideoIntroFile(null);
        onVideoClose();
      }} isCentered>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Upload Intro Video</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Text>Are you sure you want to upload <b>{videoIntroFile?.name}</b> intro video file?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='brand' mr={3} variant='outline' onClick={() => {
              setVideoIntroFile(null);
              onVideoClose();
            }}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='brand' onClick={handleUploadVideo}
                    isLoading={isUploadingVideo} isDisabled={isUploadingVideo}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteVideoOpen} onClose={() => {
        onDeleteVideoClose();
      }} isCentered>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Delete Intro Video</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Text>Are you sure you want to delete the Intro video? Prospects will no longer see it on the connect
              screen.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='brand' mr={3} variant='outline' onClick={() => {
              onDeleteVideoClose();
            }}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='brand' onClick={handleDeleteVideo}
                    isLoading={isDeletingVideoUrl} isDisabled={isDeletingVideoUrl}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isChangeSubscriptionOpen} onClose={onChangeSubscriptionClose} isCentered>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Change Subscription</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Text>Are you sure you want to change your subscription
              to <b>{subscriptionTo.includes('SMARTFINANCE') ? 'smartportfolio™' : 'smarttax™'} {getPlanName(subscriptionTo)}</b> plan?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='brand' mr={3} variant='outline' onClick={onChangeSubscriptionClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='brand' onClick={handleChangeSubscription}
                    isLoading={isChangingSubscription} isDisabled={isChangingSubscription}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isChangePaymentMethodOpen} onClose={onChangePaymentMethodClose} isCentered>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Update payment method</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Input
              mb={4}
              type='text'
              placeholder='Cardholder name'
              value={cardHolderName}
              onChange={e => setCardHolderName(e.target.value)}
            />
            <Box borderWidth={1} px={4} py={3} borderRadius={4}>
              <CardElement options={{
                style: {
                  base: {
                    fontSize: '14px',
                  },
                }
              }}/>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='brand' mr={3} variant='outline' onClick={onChangePaymentMethodClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='brand' onClick={handleUpdatePaymentMethod}
                    isLoading={isChangingSubscription} isDisabled={isChangingSubscription}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Settings;
