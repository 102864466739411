import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { MdBarChart } from 'react-icons/md';
import ReactApexChart from 'react-apexcharts';
import { CirclesWithBar } from 'react-loader-spinner';
import dateFilterOptions from '../../../../libraries/dateFilterOptions';
import Select from 'react-select';
import moment from "moment";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { DocumentIcon } from "../../../../components/icons/Icons";
import { downloadProspectusFile } from "../../../../api/fasttrack-service";

interface MarketDataModalProps {
  isLoading: boolean;
  isOpen: boolean,
  onClose: () => void,
  security: any,
  marketData: {
    priceData: {
      x: string,
      y: number
    }[],
    stats: any,
    portfolio: any,
    dividends: any,
  },
  selectedDate: string,
  handleDateChange: (value: string) => void
}

const tableColumnsTop10Holdings = [
  {
    Header: 'Symbol',
    accessor: 'ticker',
  },
  {
    Header: 'Company',
    accessor: 'name',
  },
  {
    Header: 'Assets (%)',
    accessor: 'netweight',
  },
];

const tableColumnsSector = [
  {
    Header: 'Sector',
    accessor: 'name',
  },
  {
    Header: '',
    accessor: 'progress',
  },
  {
    Header: 'Weight',
    accessor: 'netweight',
  },
];

const tableColumnsDividends: any = [
  {
    Header: 'Date',
    accessor: 'strdate',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Value',
    accessor: 'value',
    Cell: ({ value }: any) => `$${value.toFixed(2)}`,
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ value }: any) => `$${value}`,
  },
];

const MarketDataModal: React.FC<MarketDataModalProps> = ({
                                                           isLoading,
                                                           isOpen,
                                                           onClose,
                                                           security,
                                                           selectedDate,
                                                           handleDateChange,
                                                           marketData
                                                         }) => {


  // Chakra Color Mode
  const textColor = useColorModeValue("navy.700", "white");
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  );
  const { colorMode } = useColorMode();
  const dateFilters: any = dateFilterOptions;

  const [totalWeight, setTotalWeight] = useState(0);
  const [isProspectusFileDownloading, setIsProspectusFileDownloading] = useState(false);

  const diffPercentage = (((+marketData?.stats?.describe?.price - +marketData?.stats?.describe?.price_previous) / +marketData?.stats?.describe?.price_previous) * 100);

  const customStyles: any = {
    option: (base: any, state: any) => ({
      ...base,
      color: colorMode === 'dark' && 'white',
      backgroundColor: colorMode === 'light' ? 'white' : '#1B2559',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colorMode === 'light' ? '#E9EDF7' : '#707EAE',
      }
    }),
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided, opacity, transition,
        color: colorMode === 'light' ? '#1B2559' : 'white',
      };
    },
    control: (base: any) => ({
      ...base,
      backgroundColor: 'inherit',
      borderColor: 'inherit',
    }),
    input: (base: any) => ({
      ...base,
      color: colorMode === 'light' ? '#1B2559' : 'white',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#8F9BBA'
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: '#1B2559',
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: colorMode === 'light' ? 'white' : '#1B2559',
    }),
  };

  const data: any = {
    series: [{
      name: `${security?.name} (${security.tickerSymbol})`,
      data: marketData?.priceData || []
    }],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      title: {
        text: 'FT Price Range',
        align: 'left'
      },
      stroke: {
        colors: [diffPercentage < 0 ? '#d60a22' : '#037b66']
      },
      fill: {
        colors: [diffPercentage < 0 ? '#d60a22' : '#037b66']
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          },
        },
        title: {
          text: 'Price'
        },
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val: number) {
            return val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          }
        }
      }
    },
  };

  const handleClose = () => {
    onClose();
  }

  const returnsText = useBreakpointValue({
    base: security?.tickerSymbol || '',
    md: security?.name || ''
  });

  const topTenColumns = useMemo(() => tableColumnsTop10Holdings, [tableColumnsTop10Holdings]);
  const topTenData = useMemo(() => {
    if (!marketData?.portfolio || marketData?.portfolio?.err) return [];
    return marketData.portfolio.topten.datalist
  }, [marketData]);

  const sectorColumns = useMemo(() => tableColumnsSector, [tableColumnsSector]);
  const sectorData = useMemo(() => {
    if (!marketData?.portfolio || marketData?.portfolio?.err) return [];
    return marketData.portfolio.sector.datalist
  }, [marketData]);

  const flattenDividendsData = (dividendsData: any) => {
    const flatData: any = [];

    dividendsData.data.forEach((dividendRecord: any) => {
      const { marketdate, strdate } = dividendRecord.date;
      const { divs, price } = dividendRecord;

      divs.forEach((div: { type: string, type_code: string, value: number }) => {
        flatData.push({
          marketdate,
          strdate,
          code: div.type_code,
          type: div.type,
          value: div.value,
          price,
        });
      });
    });

    return flatData;
  };

  const dividendsColumns = useMemo(() => tableColumnsDividends, []);
  const dividendsData = useMemo(() => {
    if (!marketData?.dividends?.data) return [];
    return flattenDividendsData(marketData?.dividends)
  }, [marketData]);

  const dividendsTableInstance = useTable(
    {
      columns: dividendsColumns,
      data: dividendsData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps: getDivsTableProps,
    getTableBodyProps: getDivsTableBodyProps,
    headerGroups: headerDivsGroups,
    rows: divRows,
    prepareRow: prepareDivRow
  } = dividendsTableInstance;


  const topTenTableInstance = useTable(
    {
      columns: topTenColumns,
      data: topTenData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow
  } = topTenTableInstance;

  const sectorTableInstance = useTable(
    {
      columns: sectorColumns,
      data: sectorData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps: getSectorTableProps,
    getTableBodyProps: getSectorTableBodyProps,
    headerGroups: headerSectorGroups,
    page: sectorPage,
    prepareRow: prepareSectorRow
  } = sectorTableInstance;

  useEffect(() => {
    if (marketData?.portfolio && !marketData?.portfolio?.err) {
      const topten = marketData.portfolio.topten;
      let total = 0;

      topten.datalist.forEach((item: any) => {
        const weight = parseFloat(item.netweight.replace('%', ''));
        total += weight;
      });
      setTotalWeight(total);
    }
  }, [marketData]);

  function formatMarketCap(valueInMillions: string) {
    const value = parseFloat(valueInMillions.replace(/,/g, ''));

    if (isNaN(value)) {
      return 'N/A';
    }

    const million = 1;
    const billion = 1000;
    const trillion = 1000000;

    let formattedValue = valueInMillions;
    let suffix = 'M';

    // Check and format based on number size
    if (value >= trillion) {
      formattedValue = (value / trillion).toFixed(2);
      suffix = 'T';
    } else if (value >= billion) {
      formattedValue = (value / billion).toFixed(2);
      suffix = 'B';
    }
    formattedValue = parseFloat(formattedValue).toString();
    return `${formattedValue}${suffix}`;
  }

  const prospectusFile = () => {
    setIsProspectusFileDownloading(true);
    downloadProspectusFile(security.tickerSymbol)
      .then(({ link }) => {
        window.open(link, '_blank');
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsProspectusFileDownloading(false))
  }

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} size='full'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color={iconColor} fontSize='2xl'>{`${security?.name} (${security.tickerSymbol})`}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            isLoading ? <Center h="100%" minH='400px' justifyContent="center" py="32px">
                <CirclesWithBar
                  height="120"
                  width="120"
                  color="#70ad45"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  outerCircleColor=""
                  innerCircleColor=""
                  barColor=""
                  ariaLabel="circles-with-bar-loading"
                />
              </Center> :
              <Box>
                <Flex justify='space-between' direction={{ base: 'column', md: 'row' }} mb={2} gap={2}>
                  <Box>
                    <HStack>
                      <Text color={textColor} fontSize='2xl'
                            fontWeight='700'>${(security?.closePrice || marketData?.stats?.describe?.price) ? (+security?.closePrice || +marketData?.stats?.describe?.price).toFixed(2) : 0}</Text>
                      <Text color={diffPercentage > 0 ? '#037b66' : '#d60a22'} fontSize='lg' fontWeight='600'
                            hidden={diffPercentage === 0}>({diffPercentage.toFixed(2)}%) {(+marketData?.stats?.describe?.price - +marketData?.stats?.describe?.price_previous).toFixed(2)}</Text>
                    </HStack>
                    <HStack>
                      <Text color={textColor} fontSize='md'><b>At close</b>: {security?.closePriceAt}</Text>
                      <Text color={textColor} fontSize='md'><b>Last
                        Update</b>: {moment(security?.updatedAt).format('M/D/YYYY h:mm A z')}</Text>
                    </HStack>
                  </Box>
                  <Button colorScheme='brand' leftIcon={<DocumentIcon />} loadingText='Downloading...'
                          isLoading={isProspectusFileDownloading} isDisabled={isProspectusFileDownloading}
                          onClick={prospectusFile}>
                    Prospectus File
                  </Button>
                </Flex>
                <Flex justify="space-between" ps="0px" pb="12px" pe="20px">
                  <Flex align="center" w="100%" gap="10px">
                    <Box minW="180px">
                      <Select value={selectedDate} styles={customStyles}
                              options={dateFilters.filter((item: any) => item.label !== 'Custom')}
                              onChange={(option: any) => handleDateChange(option)} />
                    </Box>
                    <Button
                      ms="auto"
                      bg={bgButton}
                      _hover={bgHover}
                      _focus={bgFocus}
                      _active={bgFocus}
                      w="37px"
                      h="37px"
                      lineHeight="100%"
                      borderRadius="10px"
                    >
                      <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
                    </Button>
                  </Flex>
                </Flex>
                <ReactApexChart options={data.options} series={data.series} type="area" height={350} />
                <Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} mt={2}
                      gap={{ base: '12px', md: '32px' }}>
                  <HStack justify='space-between'>
                    <Text color={textColor} fontSize='md'>Previous Close</Text>
                    <Text color={textColor} fontSize='md'
                          fontWeight='600'>{marketData?.stats?.describe?.price_previous ? `$${(+marketData?.stats?.describe?.price_previous).toFixed(2)}` : 'N/A'}</Text>
                  </HStack>
                  <HStack justify='space-between'>
                    <Text color={textColor} fontSize='md'>Category</Text>
                    <Text color={textColor} fontSize='md' fontWeight='600'>{security?.category || 'Stock'}</Text>
                  </HStack>
                  <HStack justify='space-between'>
                    <Text color={textColor} fontSize='md'>AUM</Text>
                    <Text color={textColor} fontSize='md'
                          fontWeight='600'>{formatMarketCap(marketData?.stats?.describe?.marketcap || 'N/A') || 'N/A'}</Text>
                  </HStack>
                  <HStack justify='space-between'>
                    <Text color={textColor} fontSize='md'>Holdings Turnover</Text>
                    <Text color={textColor} fontSize='md'
                          fontWeight='600'>{`${marketData?.portfolio?.turnover || 0}%`}</Text>
                  </HStack>
                  <HStack justify='space-between'>
                    <Text color={textColor} fontSize='md'>Security Type</Text>
                    <Text color={textColor} fontSize='md'
                          fontWeight='600'>{marketData?.stats?.describe?.security_type || 'N/A'}</Text>
                  </HStack>
                  <HStack justify='space-between'>
                    <Text color={textColor} fontSize='md'>Shares out</Text>
                    <Text color={textColor} fontSize='md'
                          fontWeight='600'>{marketData?.stats?.describe?.sharesout || 'N/A'}</Text>
                  </HStack>
                  <HStack justify='space-between'>
                    <Text color={textColor} fontSize='md'>Max Draw</Text>
                    <Text color={textColor} fontSize='md'
                          fontWeight='600'>{marketData?.stats?.max?.maxdrawdown || 'N/A'}</Text>
                  </HStack>
                  <HStack justify='space-between'>
                    <Text color={textColor} fontSize='md'>52 Week Range</Text>
                    <Text color={textColor} fontSize='md'
                          fontWeight='600'>{`$${marketData?.stats?.describe?.low_52 || 0} - $${marketData?.stats?.describe?.high_52 || 0}`}</Text>
                  </HStack>
                </Grid>
                <Divider mt='16px' />
                <Box>
                  <Text color={textColor} fontSize='xl' fontWeight='700' mt={2}>
                    Performance Overview
                  </Text>
                  <Flex
                    overflowX='auto'
                    width="full"
                    py={2}
                    gap={2}
                    justify='space-between'
                    align='end'
                  >
                    <Text color={textColor} fontSize='lg' fontWeight='500' p={2} me={{ base: 8, md: 16 }}>
                      {returnsText}
                    </Text>
                    <VStack p={2}>
                      <Text fontWeight={600}>YTD</Text>
                      <Text>{marketData?.stats?.returns?.total?.ytd || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>1D</Text>
                      <Text>{marketData?.stats?.returns?.total?.oneday || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>5D</Text>
                      <Text>{marketData?.stats?.returns?.total?.fiveday || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>1M</Text>
                      <Text>{marketData?.stats?.returns?.total?.onemonth || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>3M</Text>
                      <Text>{marketData?.stats?.returns?.total?.threemonths || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>6M</Text>
                      <Text>{marketData?.stats?.returns?.total?.sixmonths || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>9M</Text>
                      <Text>{marketData?.stats?.returns?.total?.ninemonths || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>1Y</Text>
                      <Text>{marketData?.stats?.returns?.total?.one || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>3Y</Text>
                      <Text>{marketData?.stats?.returns?.total?.three || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>5Y</Text>
                      <Text>{marketData?.stats?.returns?.total?.five || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>10Y</Text>
                      <Text>{marketData?.stats?.returns?.total?.ten || 'N/A'}</Text>
                    </VStack>
                  </Flex>
                </Box>
                <Divider mt='16px' />
                <Box>
                  <Text color={textColor} fontSize='xl' fontWeight='700' mt={2}>
                    Returns By Year
                  </Text>
                  <Flex
                    overflowX='auto'
                    width="full"
                    py={2}
                    gap={2}
                    justify='space-between'
                    align='end'
                  >
                    <Text color={textColor} fontSize='lg' fontWeight='500' p={2} me={{ base: 8, md: 16 }}>
                      {returnsText}
                    </Text>
                    {
                      marketData?.stats?.returns?.years.slice(-10).map((yearData: {
                        returns: string,
                        year: string
                      }, index: number) => (
                        <VStack key={index} flexShrink={0} p={2}>
                          <Text fontWeight={600}>{index === 9 ? 'YTD' : yearData.year}</Text>
                          <Text>{yearData.returns}</Text>
                        </VStack>
                      ))
                    }
                  </Flex>
                </Box>
                <Divider mt='16px' />
                <Box>
                  <Text color={textColor} fontSize='xl' fontWeight='700' mt={2}>
                    Risk Overview
                  </Text>
                  <Flex
                    overflowX='auto'
                    width="full"
                    py={2}
                    gap={2}
                    justify='space-between'
                    align='end'
                  >
                    <Text color={textColor} fontSize='lg' fontWeight='500' p={2} me={{ base: 8, md: 16 }}>
                      {returnsText}
                    </Text>
                    <VStack p={2}>
                      <Text fontWeight={600}>SHARPE</Text>
                      <Text>{marketData?.stats?.risk?.sharpe || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>UPI</Text>
                      <Text>{marketData?.stats?.risk?.upi || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>FTALPHA</Text>
                      <Text>{marketData?.stats?.risk?.ftalpha || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>CORR</Text>
                      <Text>{marketData?.stats?.risk?.corr || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>SD</Text>
                      <Text>{marketData?.stats?.risk?.sd || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>UI</Text>
                      <Text>{marketData?.stats?.risk?.ui || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>BETA</Text>
                      <Text>{marketData?.stats?.risk?.beta || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Text fontWeight={600}>ALPHA</Text>
                      <Text>{marketData?.stats?.risk?.alpha || 'N/A'}</Text>
                    </VStack>
                  </Flex>
                </Box>
                <Divider mt='16px' />
                <Box>
                  <Text color={textColor} fontSize='xl' fontWeight='700' mt={2}>
                    Maximum Draw Down
                  </Text>
                  <Flex
                    overflowX='auto'
                    width="full"
                    py={2}
                    gap={2}
                    justify='space-between'
                    align='end'
                  >
                    <Text color={textColor} fontSize='lg' fontWeight='500' p={2} me={{ base: 8, md: 16 }}>
                      {returnsText}
                    </Text>
                    <VStack p={2}>
                      <Text fontWeight={600}>MAX DRAW</Text>
                      <Text>{marketData?.stats?.max?.maxdrawdown || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Tooltip label='Number of months security took spent in drawdown' hasArrow placement="top">
                        <Text fontWeight={600} decoration='underline' cursor='help'>LENGTH</Text>
                      </Tooltip>
                      <Text>{`${marketData?.stats?.max?.maxdrawlength} Month${marketData?.stats?.max?.maxdrawlength > 1 ? 's' : ''}` || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Tooltip label='Number of months security took to recover from drawdown' hasArrow placement="top">
                        <Text fontWeight={600} decoration='underline' cursor='help'>RECOVERY</Text>
                      </Tooltip>
                      <Text>{`${marketData?.stats?.max?.recoverylength} Month${marketData?.stats?.max?.recoverylength > 1 ? 's' : ''}` || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Tooltip label='Date fund started drawdown' hasArrow placement="top">
                        <Text fontWeight={600} decoration='underline' cursor='help'>PEAK</Text>
                      </Tooltip>
                      <Text>{marketData?.stats?.max?.peakdate || 'N/A'}</Text>
                    </VStack>
                    <VStack p={2}>
                      <Tooltip label='Date security reached lowest value' hasArrow placement="top">
                        <Text fontWeight={600} decoration='underline' cursor='help'>VALLEY</Text>
                      </Tooltip>
                      <Text>{marketData?.stats?.max?.valleydate || 'N/A'}</Text>
                    </VStack>
                  </Flex>
                </Box>
                <Divider mt='16px' />
                <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} mt={4}
                      gap={{ base: '12px', md: '32px' }}>
                  <Box>
                    <Text color={textColor} fontSize='xl' fontWeight='700'>
                      Top 10 Holdings ({isLoading ? 0 : totalWeight.toFixed(2)}%
                      of Total Assets)
                    </Text>
                    <Table {...getTableProps()} variant="striped" color="gray.500">
                      <Thead>
                        {headerGroups.map((headerGroup, index) => (
                          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                              <Th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                key={index}
                                borderColor="transparent">
                                <Flex
                                  justify="space-between"
                                  align="center"
                                  color="gray.400">
                                  {column.render('Header')}
                                </Flex>
                              </Th>
                            ))}
                          </Tr>
                        ))}
                      </Thead>
                      <Tbody {...getTableBodyProps()}>
                        {isLoading ? <>
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                            <Tr key={item}>
                              <Td colSpan={6} p={2}>
                                <Skeleton h="20px" />
                              </Td>
                            </Tr>
                          ))}
                        </> : page.map((row, index) => {
                          prepareRow(row);
                          return (
                            <Tr {...row.getRowProps()} key={index}>
                              {row.cells.map((cell, index) => {
                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize='md'
                                    py="8px"
                                    borderColor="transparent">
                                    {cell.value}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                    <Divider mt={1} />
                    <Text color='gray.500' fontSize='md' mt={1} textAlign='right'>
                      As of {marketData?.portfolio?.topten?.date}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={textColor} fontSize='xl' fontWeight='700'>
                      Sector Weightings
                    </Text>
                    <Table {...getSectorTableProps()} variant="striped" color="gray.500">
                      <Thead>
                        {headerSectorGroups.map((headerGroup, index) => (
                          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                              <Th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                key={index}
                                borderColor="transparent">
                                <Flex
                                  justify="space-between"
                                  align="center"
                                  color="gray.400">
                                  {column.render('Header')}
                                </Flex>
                              </Th>
                            ))}
                          </Tr>
                        ))}
                      </Thead>
                      <Tbody {...getSectorTableBodyProps()}>
                        {isLoading ? <>
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                            <Tr key={item}>
                              <Td colSpan={6} p={2}>
                                <Skeleton h="20px" />
                              </Td>
                            </Tr>
                          ))}
                        </> : sectorPage.map((row, index) => {
                          prepareSectorRow(row);
                          return (
                            <Tr {...row.getRowProps()} key={index}>
                              {row.cells.map((cell: any, index) => {
                                if (cell.column.Header === '') {
                                  return (
                                    <Td
                                      {...cell.getCellProps({
                                        style: {
                                          maxWidth: '100%',
                                          width: '100%'
                                        }
                                      })}
                                      key={index}
                                      fontSize='md'
                                      py="8px"
                                      borderColor="transparent">
                                      <Progress value={parseFloat(cell.row.original.netweight.replace('%', ''))}
                                                width='100%'
                                                colorScheme='orange' />
                                    </Td>
                                  );
                                }
                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize='md'
                                    py="8px"
                                    borderColor="transparent">
                                    {cell.value}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                    <Divider mt={1} />
                    <Text color='gray.500' fontSize='md' mt={1} textAlign='right'>
                      As of {marketData?.portfolio?.sector?.date}
                    </Text>
                  </Box>
                </Grid>

                <Text color={textColor} fontSize='xl' fontWeight='700' mt={4}>
                  Dividends
                </Text>
                <Box overflowX='auto'>
                  <Table {...getDivsTableProps()} variant="simple" size="sm">
                    <Thead>
                      {headerDivsGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                          {headerGroup.headers.map((column, index) => (
                            <Th {...column.getHeaderProps()} key={index}
                                color="gray.400">
                              {column.render('Header')}
                            </Th>
                          ))}
                        </Tr>
                      ))}
                    </Thead>
                    <Tbody {...getDivsTableBodyProps()}>
                      {divRows.map((row: any, index: number) => {
                        prepareDivRow(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell: any, index: number) => {
                              if (cell.column.Header === 'Type') {
                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize='md'
                                    py="8px"
                                    color="gray.500"
                                    borderColor="transparent"
                                  >
                                    {`${cell.row.original.type} (${cell.row.original.code})`}
                                  </Td>
                                )
                              }

                              return (
                                <Td
                                  {...cell.getCellProps()}
                                  key={index}
                                  fontSize='md'
                                  py="8px"
                                  color="gray.500"
                                  borderColor="transparent"
                                >
                                  {cell.render('Cell')}
                                </Td>
                              )
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Box>
              </Box>
          }
        </ModalBody>
        <ModalFooter justifyContent='start' w='full'>
          <Box w='full'>
            <Text color='gray.500' fontSize='sm'>
              Hypothetical performance shown is for illustrative purposes only and does not guarantee future results.
              Actual results will vary. Smartdata Wealth Inc. does not guarantee the accuracy or completeness of
              information provided. There may be delays, omissions, or inaccuracies in the information.
            </Text>
            <HStack justify='end'>
              <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Close</Button>
            </HStack>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default MarketDataModal;
